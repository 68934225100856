import React from "react";

import "./Hero.css";

import hero from "../assets/hero.jpg";
import heroMobile from "../assets/hero-mobile.jpg";

const Hero = () => {
  return (
    <React.Fragment>
      <img src={hero} className="Hero" alt="heroic man" />
      <img src={heroMobile} className="Hero-mobile" alt="heroic man" />
    </React.Fragment>
  );
};
export default Hero;
